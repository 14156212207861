h1,.h1 {
  @extend .condensed-sans;
  margin: 0 0 $grid-gutter-width;
  line-height: .95;
  @include media-breakpoint-up(md) {
   margin-bottom: $grid-gutter-width*1.33;
  }
}
h4,.h4 {
  letter-spacing: .125em;
  text-transform: uppercase;
}
