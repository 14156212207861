.block-blog,.block-podcasts,.library-block {
  .article-grid {
    @include tile-grid;
    article {
      .article-link {
        figure {
          @include caption-overlay;
        }
      }
    }
  }
}
.single {
  .hentry.block {
    .featured-img {
      margin-top: -$grid-gutter-width/2;
      margin-bottom: $grid-gutter-width*1.5;
      @include media-breakpoint-down(xs) {
        margin-top: -$grid-gutter-width*1.5;
        padding: 0;
      }
      @include media-breakpoint-up(md) {
//         margin-bottom: $grid-gutter-width*2;
      }
      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-width*2;
      }
      figure {
        @include figure-aspect-ratio('13/7');
      }
    }
    time {
      @extend .bg-white;
      display: block;
      align-items: center;
      text-align: center;
      letter-spacing: .125em;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      width: 90px;
      height: 90px;
      border-radius: 50%;
      padding: $grid-gutter-width/2 $grid-gutter-width/3 $grid-gutter-width/3;
      margin-bottom: $grid-gutter-width;
      font-size: $font-size-md;
      b{
        @extend .h1;
        display: block;
        margin: 0;
        font-weight: $font-weight-base;
        line-height: 1;
      }
      @include media-breakpoint-down(md) {
        font-size: $font-size-xs;
        b {
          font-size: $font-size-h3;
        }
      }
      @include media-breakpoint-up(lg) {
        padding-top: $grid-gutter-width*.4;
        line-height: 1.6;
        width: 130px;
        height: 130px;
      }
      @include media-breakpoint-up(xl) {
        padding-top: $grid-gutter-width*.66;
        font-size: $font-size-base;
        line-height: 1.8;
        width: 160px;
        height: 160px;
      }
    }
    .event-time {
      margin-bottom: 1rem;
    }
    .post-meta {
      text-transform: uppercase;
      letter-spacing: .1em;
      font-size: $font-size-sm;
      text-align: center;
      b {
        @extend .h4;
        font-size: $font-size-sm;
        display: block;
        margin-bottom: 0;
      }
    }
    .content {
      text-align: left;
      time {
        margin: $grid-gutter-width/2 auto;
      }
      h1.entry-title {
        line-height: .95;
      }
      h2.entry-subhead {
        font-size: $font-size-lg;
        margin-bottom: $grid-gutter-width;
      }
      p.action {
        margin-top: $grid-gutter-width*1.5;
        text-align: center;
      }
      .event-detail-block {
        @include last-child-zero-margin;
        margin-bottom: $block-gutter/2;
        padding: 1em;
        b {
          @include allcaps-style;
        }
      }
    }
  }
  &.single-event {
    .hentry.block {
      time {
        @extend .bg-orange;
      }
    }
  }
}
.posts-navigation {
  @extend .bg-gray-lightest;
  @include wp-full-width;
  padding: $grid-gutter-width;
  .nav-links {
    @extend .row;
    .nav-previous,.nav-next {
      @extend .col-6;
      text-align: left;
      a {
        @extend .btn;
      }
    }
    .nav-next {
      text-align: right;
      margin-left: auto;
    }
  }
}
nav.categories {
  @extend .bg-white;
  padding: $grid-gutter-width/2;
  @include media-breakpoint-down(sm) {
//     display: none;
  }
  ul {
    @extend .list-unstyle;
    text-align: center;
    li {
      display: inline-block;
      margin: 0 $grid-gutter-width/3;
      a {
        color: $body-color;
        font-weight: $font-weight-bold;
        text-decoration: none;
        &:hover,&.active {
          color: $link-color;
        }
      }
    }
  }
}
.category-description {
  @include make-col-ready();
  @include main-column-width;
  text-align: center;
}
