html {
  scroll-behavior: smooth;
  scroll-padding-top: 120px;
  font-size: 16px;
//   font-size: 14px;
  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
}
body {
  padding-top: $header-height;
  @include media-breakpoint-up(md) {
    padding-top: $header-height-lg;
  }
/*
  &.logged-in.admin-bar {
    padding-top: $adminbar-height-sm + $header-height;
    @media screen and (min-width: 783px) {
      padding-top: $adminbar-height + $header-height-lg;
    }
  }
*/
  &.alertbar-engaged {
    padding-top: $header-height + $alertbar-min-height;
    @include media-breakpoint-up(md) {
      padding-top: $header-height-lg + $alertbar-min-height;
    }
  }
}
.wrap {
  justify-content: center;
  text-align: center;
}
a {
  @include transition($link-transition);
}
img {
  @extend .img-fluid;
}
figure.headshot {
  border-radius: 50%;
}
.borders {
  figure.headshot {
    img {
      border: 5px solid $white;
    }
  }
}
.icon-links {
  @extend .list-unstyle;
  text-align: center;
  li {
    display: inline-block;
    margin: 0 $grid-gutter-width/6;
    a {
      color: $gray-dark;
      text-decoration: none;
      &:hover {
        color: $body-color;
      }
    }
  }
}
.buttons-list {
  @include list-normalize;
}
.page-header {
  .single-post &, .single-event &, .blog &, .archive &, .category &, .page-id-19 &, .page-id-2037 &  {
    @extend .bg-slate-dark;
//     padding: $grid-gutter-width $grid-gutter-width/2;
    h1 {
//       margin: 0;
    }
    h2 {
      @extend .h4;
      margin: $grid-gutter-width/6 0 0;

    }
  }
  .no-heading & {
    display: none;
  }
  &:not(.page-header-image) {
    h1 {
      @include h1ify-lg;
      margin-top: $grid-gutter-width;
    }
  }
}
.page-header-image {
  @extend .bg-slate;
  position: relative;
  margin-bottom: $block-gutter;
  img {
    width: 100%;
    opacity: .8;
  }
  h1 {
    @include headline-size;
    position: absolute;
    left: $grid-gutter-width;
    right: $grid-gutter-width;
    top: 50%;
    transform: translateY(-50%);
    color: $white-true;
  }
}
