// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.form-control {
  font: $input-font-style $input-font-size $input-font-family;
  &:focus {
		&::-webkit-input-placeholder {
		  color: transparent;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: transparent;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: transparent;
		}
		&:-ms-input-placeholder {
		  color: transparent;
		}
	}
}
.bg-orange, block-subscribe {
  .form-control {
    color: $orange;
		&::-webkit-input-placeholder {
		  color: $orange;
		}
		&:-moz-placeholder { /* Firefox 18- */
		  color: $orange;
		}
		&::-moz-placeholder {  /* Firefox 19+ */
		  color: $orange;
		}
		&:-ms-input-placeholder {
		  color: $orange;
		}
    &:focus {
  		&::-webkit-input-placeholder {
  		  color: transparent;
  		}
  		&:-moz-placeholder { /* Firefox 18- */
  		  color: transparent;
  		}
  		&::-moz-placeholder {  /* Firefox 19+ */
  		  color: transparent;
  		}
  		&:-ms-input-placeholder {
  		  color: transparent;
  		}
  	}
  }
}
#formwrap {
  padding: $grid-gutter-width-base;
  border: 1px solid $white;
  margin-bottom: $grid-gutter-width-base;
  border-radius: 20px;
  .hbspt-form {
    text-align: center !important;
    .hs-richtext.hs-main-font-element {
      background-color: gray;
      h1 {
        @extend .h3;
        font-family: $font-family-sans !important;
        text-align: center !important;
      }
    }
  }
}
.gform_wrapper {
  input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
  select, textarea {
    @extend .form-control;
    padding: $input-padding-y $input-padding-x !important;
  }
  .gfield {
    margin: 0 0 $grid-gutter-width !important;
    padding: 0 !important;
    label {
      @extend .sr-only;
    }
    &.gfield_error {
      background-color: transparent !important;
      border: none !important;
      width: 100% !important;
      max-width: none;
      margin: 0 0 $grid-gutter-width !important;
      .validation_message {
        padding: 5px 0 0 !important;
      }
      input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
      select, textarea {
        border: $border-width solid $slate;
      }
    }
    .ginput_recaptcha {
      &>div {
        margin: 0 auto;
      }
    }
  }
  .gform_footer,.gform_page_footer {
    padding: 0 !important;
    margin: 0 !important;
    text-align: center;
    input.button, input[type=submit] {
      @extend .btn;
      width: auto !important;
    }
  }
}

@media only screen and (min-width: 641px) {
  .block-contact .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
    background-color: transparent !important;
    border: none !important;
    width: 100% !important;
    max-width: none !important;
    margin: 0 0 $grid-gutter-width !important;
    .validation_message {
      padding: 5px 0 0 !important;
    }
  }
}
