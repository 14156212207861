.condensed-sans {
  font-family: $font-family-condensed;
  font-weight: $font-weight-base;
  text-transform: uppercase;
}
.alt-color {
  color: $brand-primary;
}
.bg-orange,.bg-slate,.bg-slate-dark,.bg-slate-light,
.has-orange-background-color,
.has-slate-background-color,
.has-slate-dark-background-color ,
.has-slate-light-background-color {
  color: $white;
/*
  .btn {
    @extend .btn-alt;
  }
*/
  svg {
    path {
      fill: $white;
    }
  }
  a.article-link {
    color: $white !important;
  }
}
.bg-orange,.has-orange-background-color {
  background-color: $orange;
  .btn {
    @extend .btn-orange;
  }
  a.article-link {
    &:hover,&:active,&:focus,&:focus-visible {
      .entry-details {
        .entry-title {
          color: $black !important;
        }
      }
    }
  }
  .alt-color {
    color: $body-color;
  }
}
.bg-slate,.has-slate-background-color {
  background-color: $slate;
/*
  .btn {
    @extend .btn-slate;
  }
*/
}
.bg-slate-dark,.has-slate-dark-background-color  {
  background-color: $slate-dark;
/*
  .btn {
    @extend .btn-slate-dark;
  }
*/
}
.bg-slate-light,.has-slate-light-background-color  {
  background-color: $slate-light;
}
.bg-white,.has-white-off-background-color  {
  background-color: $white;
}
.bg-white-true,.has-white-background-color {
  background-color: $white-true;
}
.list-unstyle {
  margin: 0;
  padding: 0;
  list-style: none;
}
.bg-gray,.has-gray-background-color {
  background-color: $gray;
}
.bg-gray-light,.has-gray-light-background-color {
  background-color: $gray-light;
}
.bg-gray-lightest,.has-gray-lightest-background-color {
  background-color: $gray-lightest;
}
.bg-gray-dark,.has-gray-dark-background-color {
  background-color: $gray-dark;
}
