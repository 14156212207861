/* GENERAL BLOCKS
  --------------------------------------- */
.wp-full-width {
  @include wp-full-width-no-padding;
}
.vcet-block,.vcet-block-group {
  margin-top: $block-gutter;
  margin-bottom: $block-gutter;
  padding-top: $block-gutter;
  padding-bottom: $block-gutter;
  &:not(.bg-white) {
    &+.vcet-block.bg-white,&.vcet-block-group.bg-white {
      margin-top: -$block-gutter;
    }
  }
  &+.vcet-block:not(.bg-white),&+.vcet-block-group:not(.bg-white),&+#sb_instagram,&+#sb_instagram.sbi_width_resp  {
    margin-top: -$block-gutter;
  }
  &.bg-white,&.stats-block {
    &+.vcet-block.bg-white,&+.vcet-block-group.bg-white {
      margin-top: -$block-gutter;
    }
    &+.learn-more-block {
      margin-top: -$block-gutter;
    }
  }
  h2 {
    @include h1ify-lg;
  }
  &+p {
    &+.mailmunch-forms-after-post {
      margin-top: -$block-gutter;
    }
  }
  &.final-block {
    margin-bottom: -($block-gutter + 18px);
  }
  .block-overview {
    @include make-col-ready();
    @include main-column-width;
  }
}
.mailmunch-forms-before-post,.page-header {
  &+.vcet-block:not(.hero-block),&+.vcet-block-group:not(.hero-block) {
    margin-top: -$block-gutter;
  }
  &+.hero-block {
    margin-top: 0;
  }
}
.vcet-block {
  .details {
    .vcet-block-header {
      text-transform: uppercase;
      font-size: $font-size-hero-sm;
    }
  }
}
main.main {
  padding-bottom: $block-gutter;
  :last-child {
    &.vcet-block-group, &.vcet-block,&.posts-navigation {
      margin-bottom: -$block-gutter;
    }
  }
}
#sb_instagram,#sb_instagram.sbi_width_resp {
  @include wp-full-width;
  @extend .bg-slate;
//   margin-bottom: $block-gutter;
  margin-bottom: -$block-gutter;
  #sbi_images {
    padding: 0;
  }
  &+.vcet-block,&+.vcet-block-group {
    margin-top: -$block-gutter;
  }
}
.accordion-block {
  margin-top: $block-gutter;
  margin-bottom: $block-gutter;
}

.blog-featured-block {
  .details {
    @include last-child-zero-margin;
  }
  .article-grid {
    .hentry {
      margin-top: $grid-gutter-width;
      margin-bottom: 0
    }
  }
}
.block-blog:not(.vcet-block) {
  margin-bottom: 0;
  margin-top: 0;
}
.hero-block {
  position: relative;
  padding: 0;
  .message {
    color: $white-true;
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;
    .container,.container-fluid {
      @include last-child-zero-margin;
      h2 {
        @include condensify;
        @include headline-size;
        color: $white-true;
      }
    }
  }
  .slideshow-wrap {
    width: 100%;
    img {
      width: 100%;
      opacity: .75;
    }
  }
}
.library-block {
  .hentry {
    margin-bottom: 0;
    margin-top: $grid-gutter-width*1.5;
  }
}
.logos-block {
  .logo-grid {
    .logo {
      margin-top: $block-gutter;
      figure {
        @include figure-aspect-ratio('2/1');
        img {
          will-change: transform, opacity;
          transition: all ease .4s;
        }
        a {
          img {
            opacity: .9;
          }
          &:hover,&:active,&:focus,&:focus-visible {
            img {
              transform: scale(1.02);
              opacity: 1;
            }
          }
        }
      }
      &.square600 {
        figure {
          @include figure-aspect-ratio('1/1');
        }
      }
    }
  }
}
.portfolio-grid {
  .logo {
    margin-bottom: $grid-gutter-width;
    div.logo-wrap {
      @include d-flex;
      align-items: center;
      justify-content: center;
//       height: 100%;
      flex-direction: column;
      margin-bottom: 1em;
    }
    figure {
      @include d-flex;
      position: relative;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding: 16px;
      margin: 0;
      img:not(.acquired) {
        will-change: transform, opacity;
        transition: all ease .4s;
        max-height: 140px;
        width: auto;
      }
      img.acquired {
        position: absolute;
        top: 0;
        left: 0;
        width: 120px;
      }
      a {
        img:not(.acquired) {
          opacity: .9;
        }
        @include hover-focus {
          img:not(.acquired) {
            transform: scale(1.05);
            opacity: 1;
          }
        }
      }
    }
    .content {
      font-size: $font-size-md;
      line-height: 1.3;
      h3 {
        @include condensify;
      }
    }
  }
}
.quick-links-list {
  @include list-normalize;
  margin-bottom: $block-gutter;
  li {
    a {
      @include d-flex;
      @include link-no-underline;
      font-family: $font-family-sans;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: .1em;
      justify-content: space-between;
      align-items: center;
      padding: .5em;
      border-bottom: 1px solid $gray-light;
      i[class*="fa-"] {
        margin-right: .5em;
        @include transition($indicator-transition);
      }
      &:hover,&:active,&:focus,&:focus-visible {
        i[class*="fa-"] {
          margin-right: 0;
        }
      }
    }
  }
}
.testimonials-block {
  .details {
    @include last-child-zero-margin;
  }
  .testimonials {
    &>.col-12 {
      .testimonial {
        margin-top: $block-gutter;
        align-items: center;
        font-size: $font-size-lg;
        figure {
          @include figure-aspect-ratio('1/1');
          @include media-breakpoint-up(md) {
            margin-bottom: 0;
          }
        }
        .quote {
          @include last-child-zero-margin;
          text-align: left;
        }
        h3,.role {
          @include condensify;
          text-transform: none;
          line-height: 1.1;
          font-size: $font-size-h3;
          margin-bottom: 0;
        }
      }
      &:nth-child(even) {
         @include media-breakpoint-up(md) {
          .testimonial {
            flex-direction: row-reverse;
            .quote {
              text-align: right;
            }
          }
        }
      }

    }
  }
}
.tiles-grid {
  @include tile-grid;
  article {
    margin-top: $grid-gutter-width;
    margin-bottom: 0;
    .article-link {
      figure {
        @include caption-overlay;

      }
    }
  }
}

.fact-bubbles-block {
  .facts {
    @include fact-bubbles;
  }
}
