.block-subscribe {
  @extend .bg-orange;
  h3 {
    margin-bottom: 1.1em;
  }
  .btn {
    @extend .btn-orange;
  }
  #mc_embed_signup {
    margin-bottom: $grid-gutter-width*2;
    .input-group {
      .form-control {
        border-color: $white;
        border-right: none;
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        z-index: 0;
      }
      .input-group-append {
        .btn {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
  .hbspt-form {
    h1 {
      display: none !important;
    }
  }
}
.content-info {
  @extend .bg-slate-dark;
  text-align: center;
  .accent {
    height: $grid-gutter-width*2.5;
    background: url(../images/footer-accent.jpg) no-repeat center center;
    background-size: cover;
  }
  .social-nav, .copyright {
    text-align: center;
  }
  .social-nav {
    @extend .bg-slate;
    padding-top: $grid-gutter-width*.66;
    padding-bottom: $grid-gutter-width*.66;
    ul {
      @extend .list-unstyle;
      li {
        display: inline-block;
        margin: 0 $grid-gutter-width/2;
        a {
          color: $white;
          text-decoration: none;
          font-size: $font-size-lg;
          &:hover {
            color: $brand-primary;
          }
        }
      }
    }
  }
  .copyright {
    padding-top: $grid-gutter-width;
    padding-bottom: $grid-gutter-width;
    text-transform: uppercase;
    font-size: $font-size-2xs;
    font-weight: $font-weight-medium;
    letter-spacing: .1em;
    p:last-child {
      margin-bottom: 0;
    }
  }
}
