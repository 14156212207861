// Grid system
/*
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
*/
/*
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}
*/
.container-fluid {
  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}
body {
  main.main {
    @include make-col-ready();
    @include main-column-width;
    &>ul {
      text-align: left;
    }
  }
  &.single-post,&.single-event {
    main.main {
      @include main-column-width-full;
    }
  }
}
