// Grid settings
$main-sm-columns:         12;
$sidebar-sm-columns:      4;
$spacer:                  1rem;
$grid-gutter-width-base:  30px;
$grid-gutter-width: $grid-gutter-width-base;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base
);
$border-width:    2px;
$form-height:     50px;
$border-radius:   $form-height/2;

$block-gutter:    $grid-gutter-width*2;

$header-height:           81px;
$header-height-lg:        116px;
$adminbar-height:         32px;
$adminbar-height-sm:      46px;
$alertbar-min-height:     36px;
$alertbar-dismiss-width:  $alertbar-min-height/3;


// Colors
$orange:                #F37021;
$slate:                 #4A5563;
$slate-light:           #5F6D80;
$slate-dark:            #39424D;
$gray:                  #BEC6C2;
$gray-light:            #DCE5E1;
$gray-lightest:         #f2f2f2;
$gray-dark:             #ABB2B2;
$white:                 #EDF2F0;
$white-true:            #fff;
$black:                 #000;

$brand-primary:         $orange;
$brand-secondary:       $slate;

$body-bg:               $white-true;
$body-color:            $brand-secondary;

$menu_width: 375px;

$link-color:            $brand-primary;
$link-hover-color:      $body-color;
$link-decoration:       none;
$link-hover-decoration: underline;
$link-transition:       all .125s ease-in-out;
$caret-transition:      transform .08s linear;
$indicator-transition:  all .25s ease-in-out;

// Fonts
$font-family-condensed: "league-gothic",Georgia, "Times New Roman", Times, serif;
$font-family-sans:      "futura-pt","Helvetica Neue", Arial, sans-serif;
$font-family-base:      $font-family-sans;

$font-size-base:      1.1rem;        // 18
$font-size-lg:        1.333rem;    // 24
$font-size-xl:        2rem;        // 36
$font-size-2xl:       2.444rem;    // 44
$font-size-md:        .888rem;     // 16
$font-size-sm:        .777rem;     // 14
$font-size-xs:        .666rem;     // 12
$font-size-2xs:       .555rem;     // 10

$font-size-hero:      6rem;        // 108
$font-size-hero-md:   5rem;        // 90
$font-size-hero-sm:   4rem;        // 72

$font-size-h1:        3rem;        // 54
$font-size-h2:        2.666rem;    // 48
$font-size-h3:        1.4rem;      // 27
$font-size-h4:        1rem;        // 18
$font-size-h5:        .944rem;     // 17
$font-size-h6:        .888rem;     // 16

$font-weight-normal:  400;
$font-weight-medium:  500;
$font-weight-bold:    700;

$headings-margin-bottom: ($spacer / 2);
$headings-font-family:   $font-family-base;
$headings-font-weight:   $font-weight-bold;
$headings-line-height:   1.2;
$headings-color:         inherit;


// Buttons
$btn-padding-x:          $grid-gutter-width;
$btn-padding-y:          $grid-gutter-width/3;
$btn-line-height:        1;
$btn-font-weight:        $font-weight-bold;
$btn-box-shadow:         none;
$btn-focus-box-shadow:   none;
$btn-active-box-shadow:  none;
$btn-border-width:       $border-width;
$btn-height:             $form-height;

$btn-color:              $body-color;
$btn-bg:                 transparent;
$btn-border-color:       $btn-color;
$btn-hover-color:        $white;
$btn-hover-bg:           $btn-color;

$btn-primary-color:              $white-true;
$btn-primary-bg:                 $brand-primary;
$btn-primary-border-color:       $btn-primary-color;
$btn-primary-hover-color:        $white-true;
$btn-primary-hover-bg:           $brand-secondary;

$btn-alt-color:          $white;
$btn-alt-bg:             transparent;
$btn-alt-border-color:   $btn-alt-color;
$btn-alt-hover-color:    $body-color;
$btn-alt-hover-bg:       $btn-alt-color;

// Forms
$input-padding-x:           $grid-gutter-width;
$input-padding-y:           $grid-gutter-width/3;
$input-line-height:         1;

$input-font-size:           1.15rem;
$input-font-style:          italic;
$input-font-family:         $font-family-base;

$input-bg:                  $white-true;
$input-bg-disabled:         $gray;

$input-color:               $body-color;
$input-border-color:        $white;
$input-box-shadow:          none;

$input-border-radius:       $border-radius;

$input-border-focus:        $input-border-color;
$input-box-shadow-focus:    none;
$input-color-focus:         $input-color;

$input-color-placeholder:   $gray-dark;

$input-height:              $form-height;
$input-transition:          none;



/* MISC OVERRIDES
  --------------------------------------- */
$navbar-brand-height:               auto;
$navbar-brand-padding-y:            auto;
$custom-select-padding-y:           1px;
$custom-select-padding-x:           1px;
$custom-select-indicator-padding:   1px;
$custom-select-feedback-icon-padding-right: auto;

$table-accent-bg:                   $gray;
