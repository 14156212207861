.card {
  background-color: transparent;
  background-clip: border-box;
  border: none;
  text-align: left;
  margin-bottom: 1em;
  .card-body {
    padding: .5rem;
  }
  .card-header {
    padding: 0;
//     background-color: $slate;
    border: none;
    &:first-child {
      border-radius: 0;
    }
    a:not(.btn) {
      @include link-no-underline;
      @include link-plus-transition;
      padding: .5rem;
      color: $body-color;
      h3 {
        color: $body-color;
        margin: 0;
      }
      h3,i[class*="fa-"] {
        color: $body-color;
        margin-right: 10px;
      }

      &:hover,&:active,&:focus,&:focus-visible {
        h3,i[class*="fa-"] {
          color: $brand-primary;
        }
      }
    }
  }
}
