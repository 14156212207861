/*
.page {
  .content {
    justify-content: center;
    .main {
      @extend .col-12;
      @extend .col-md-10;
      @extend .col-lg-8;
    }
  }
  .block-sectors {
    .content {
      .main {
        @extend .col-md-12;
        @extend .col-lg-12;
      }
    }
  }
  &.faq {
    .content {
      .main {
        @extend .col-md-12;
        @extend .col-lg-12;
      }
    }
  }
}
*/
.block,.vcet-block-group {
  padding: $grid-gutter-width*1.5 0 $grid-gutter-width;
  @include media-breakpoint-up(md) {
    padding: $block-gutter 0 $grid-gutter-width*1.75;
  }
  &.block-subscribe {
    padding: $grid-gutter-width*1.5 0;
    @include media-breakpoint-up(md) {
      padding: $block-gutter 0;
    }
  }
  &.block-main {
    .no-heading & {
      padding-top: 0;
    }
  }
}
.block-people {
  padding-bottom: $grid-gutter-width;
  .individuals {
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    justify-content: center;
    .individual {
      margin-bottom: $grid-gutter-width;
      h3,.role,.job {
        line-height: 1.1;
      }
      h3 {
        margin-bottom: .2em;
      }
      .job {
        font-size: $font-size-sm;
        font-style: italic;
        margin-bottom: .6rem;
      }
    }
  }

  p.action {
    margin-top: $grid-gutter-width*1.5;
  }
}
.block-places {
  padding-top: 0;
  .location {
    header {
      position: relative;
      img {
        width: 100%;
      }
      h1 {
        position: absolute;
        left: $grid-gutter-width/2;
        right: $grid-gutter-width/2;
        top: 45%;
        transform: translateY(-45%);
        color: $white-true;
      }
    }
    .block {
      padding: $grid-gutter-width 0 $grid-gutter-width*1.33;
      @include media-breakpoint-up(md) {
        padding: $block-gutter 0 $block-gutter.5;
      }
      &.galleries {
        padding: $grid-gutter-width 0 $grid-gutter-width*.66;
        @include media-breakpoint-up(md) {
          padding: $block-gutter 0 $grid-gutter-width*1.5;
        }
      }
      &.block-gallery {
        padding-top: 0;
        padding-bottom: $grid-gutter-width*1.66;
        @include media-breakpoint-up(md) {
          padding-bottom: $block-gutter.66;
        }
        .row {
          margin: 0;
          .col-6 {
            padding: 0;
            img {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
.block-portfolio {
  padding-bottom: $grid-gutter-width;
  .companies {
    margin-top: $grid-gutter-width;
    justify-content: center;
    .company {
      margin-bottom: $grid-gutter-width;
      h3 {
        line-height: 1.1;
      }
      .description {
        line-height: 1.3;
      }
      &.additional {
        display: none;
      }
    }
  }
  p.action {
    margin-top: $grid-gutter-width;
    .less {
      display: none;
    }
  }
}
.block-seed {
  text-align: left;
  h1 {
    text-align: center;
  }
  .full-content {
    display: none;
  }
  p.action {
    margin-top: $grid-gutter-width;
    text-align: center;
    .less {
      display: none;
    }
  }
}
.block-perks {
  padding-top: 0;
  padding-bottom: $grid-gutter-width;
//   margin-bottom: $grid-gutter-width*3;
  .perks {
    justify-content: center;
    .perk {
      margin-bottom: $grid-gutter-width*1.5;
      line-height: 1.1;
      font-size: $font-size-sm;
      img {
        visibility: hidden;
        max-width: 52px;
        display: block;
        margin: 0 auto $grid-gutter-width/2;
      }
      svg {
        max-width: 52px;
        display: block;
        margin: 0 auto $grid-gutter-width/3;
      }
    }
  }
  .scalehere-perks {
    justify-content: center;
    .perk {
      margin-bottom: $grid-gutter-width*1.5;
      align-self: center;
    }
  }
}
.page-id-1489 {
  .block-buttons {
    padding-top: 0;
    padding-bottom: 0;
  }
}
.block-membership {
  padding-bottom: 0;
//   margin-bottom: $block-gutter;
  .memberships {
    @extend .bg-slate-dark;
    margin-top: $grid-gutter-width;
    justify-content: center;
    .membership {
      padding: 0;
      a {
        color: $white-true;
        text-decoration: none;
        display: block;
        position: relative;
        img {
          -webkit-backface-visibility: hidden;
          position: relative;
          opacity: .75;
          transition: opacity 0.15s linear;
          -webkit-transform: translateZ(0);
          width: calc(100% + .49px);
        }
        h3 {
          position: absolute;
          left: $grid-gutter-width/2;
          right: $grid-gutter-width/2;
          top: 50%;
          transform: translateY(-50%);
        }
        &:hover {
          img {
            opacity: .9;
          }
        }
      }
    }
  }
}
.block-buttons {
  .btn {
    margin: 0 $grid-gutter-width/2 $grid-gutter-width/2;
    min-width: 300px;
    display: inline-block;
    padding: 14px;
  }
}
.block-launcher {
  h3 {
    color: $brand-primary;
    margin-bottom: $grid-gutter-width;
  }
  img {
    margin: $grid-gutter-width auto;
  }
  .btn {
    margin: 0 $grid-gutter-width/2 $grid-gutter-width/2;
    min-width: 300px;
    display: inline-block;
    padding: 14px;
  }
}
.page {
  .block-sectors {
    .content {
      .main {
        ul {
          @extend .list-unstyle;
          @extend .h4;
          margin-top: $grid-gutter-width;
          @include media-breakpoint-up(lg) {
            columns: 2;
            column-gap: $block-gutter;
          }
          li {
            margin-bottom: .5em;
          }
        }
      }
    }
  }
}
.faq {
  text-align: left;
  margin-bottom: $grid-gutter-width;
  .question {
    p {
      @extend .h3;
    }
  }
}
.maps {
  @extend .bg-white;
  .row {
    margin: 0;
    .map {
      padding: $grid-gutter-width $grid-gutter-width/2;
      h4 {
        margin-bottom: .25rem;
      }
    }
  }
}
.block-about-impact {
  .facts {
    .fact {
      margin-bottom: $grid-gutter-width;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
      article {
        @extend .bg-slate;
        @extend .h3;
        line-height: 1.1;
        display: flex;
        align-items: center;
        padding: $grid-gutter-width/3;
        margin: 0 auto;
        width: 240px;
        height: 240px;
        border-radius: 50%;
/*
        @include media-breakpoint-up(md) {
          width: 210px;
          height: 210px;
        }
*/
        @include media-breakpoint-up(lg) {
          width: 210px;
          height: 210px;
        }
        @include media-breakpoint-up(xl) {
          width: 255px;
          height: 255px;
        }
        p {
          margin: 0;
        }
      }
    }
  }
}

.block-about-impact-content {
   ul {
    @extend .list-unstyle;
    margin-bottom: $grid-gutter-width;
    li {
      margin-bottom: .5em;
      a {
        @extend .h4;
      }
    }
  }
}
.block-contact {
}
.scalehere-featured-img {
  img {
    max-width: 320px;
    margin: $grid-gutter-width*1.5 auto;
  }
}
.page-header-scalehere {
  @extend .bg-orange;
  padding: $grid-gutter-width 0;
  h1 {
    text-transform: none;
    margin: 0;
  }
}
