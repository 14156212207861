
.block-join {
  padding-bottom: $grid-gutter-width;
  .tiles {
    margin-top: $grid-gutter-width;
    .tile {
      margin-bottom: $grid-gutter-width;
      article {
        @extend .bg-slate;
        padding: $grid-gutter-width;
        h3 {
          margin-bottom: $grid-gutter-width*1.5;
        }
        p {
          &.action {
            margin-top: $grid-gutter-width*1.5;
            margin-bottom: 0;
          }
        }
        @include media-breakpoint-up(lg) {
          padding: $grid-gutter-width $grid-gutter-width*1.5;
        }
      }
      &.col-md-12 {
        article {
          @include media-breakpoint-up(lg) {
            padding: $grid-gutter-width $grid-gutter-width*4.5;
          }
          @include media-breakpoint-up(xl) {
            padding: $grid-gutter-width $grid-gutter-width*6;
          }
        }
      }
    }
  }
}
.block-impact {
  .facts {
    margin-top: $grid-gutter-width;
    margin-bottom: $grid-gutter-width;
    justify-content: center;
    .fact {
      margin-bottom: $grid-gutter-width;
/*
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
*/
      article {
        @extend .bg-orange;
        @extend .h1;
        display: flex;
        align-items: center;
        padding: $grid-gutter-width/2;
        margin: 0 auto;
        width: 240px;
        height: 240px;
        border-radius: 120px;
        @include media-breakpoint-down(md) {
          font-size: $font-size-h2;
        }
        @include media-breakpoint-up(md) {
          padding: $grid-gutter-width/3;
          width: 210px;
          height: 210px;
          border-radius: 105px;
        }
        @include media-breakpoint-up(lg) {
          padding: $grid-gutter-width;
          width: 288px;
          height: 288px;
          border-radius: 144px;
        }
        @include media-breakpoint-up(xl) {
          width: 350px;
          height: 350px;
          border-radius: 175px;
        }
        p {
          margin: 0 auto;
        }
      }
    }
  }
  p.action {
    margin-top: $grid-gutter-width*1.5;
    @include media-breakpoint-up(md) {
      margin-top: $grid-gutter-width*2.5;
    }
    @include media-breakpoint-up(lg) {
      margin-top: $grid-gutter-width*3;
    }
  }
}
body.home {
  main.main {
    padding-bottom: 0;
  }
}
