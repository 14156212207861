header.banner {
  @extend .bg-slate;
  @extend .clearfix;
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: $header-height;
  .logged-in.admin-bar & {
    top: 46px;
    @media screen and (min-width: 783px) {
      top: 32px;
    }
  }
  @include media-breakpoint-up(md) {
    height: $header-height-lg;
  }
  &>.container-fluid {
    padding-top: $grid-gutter-width/3;
    padding-bottom: $grid-gutter-width/3;
    @include media-breakpoint-up(md) {
      padding-top: $grid-gutter-width;
      padding-bottom: $grid-gutter-width;
    }
  }
  .alertbar-engaged & {
    height: $header-height + $alertbar-min-height;
    @include media-breakpoint-up(md) {
      height: $header-height-lg + $alertbar-min-height;
    }
  }
  .brand {
    display: block;
    width: 100%;
    max-width: 220px;
    margin-top: 5px;
    @include media-breakpoint-up(md) {
      max-width: 240px;
      margin-top: 0;
    }
  }
  #menu-primary-navigation {
    margin: 0;
    padding: 0;
    li {
      padding: 0;
      margin: 0;
      line-height: .9;
      a {
        @extend .condensed-sans;
        font-size: $font-size-2xl;
        color: $white;
//         display: block;
        padding: $grid-gutter-width/5 0;
        text-decoration: none;
        &:hover {
          color: $slate-dark;
        }
      }
      &.active {
        a {
          color: $slate-dark;
        }
      }
    }

  }
  #menu-secondary-navigation {
    margin: $grid-gutter-width 0 0;
    padding: 0;
    li {
      padding: 0;
      margin: 0;
      a {
        color: $white;
//         display: block;
        padding: $grid-gutter-width/10 0;
        text-decoration: none;
        &:hover {
          color: $slate-dark;
        }
      }
      &.active {
        a {
          color: $slate-dark;
        }
      }
    }
  }
  .pushy {
    @extend .bg-orange;
    z-index: 100;
    padding: $grid-gutter-width $grid-gutter-width $grid-gutter-width $grid-gutter-width*1.5;
    transition: transform .3s cubic-bezier(.16,.68,.43,.99);
    @include media-breakpoint-up(md) {
//       padding-top: $grid-gutter-width*2;
    }
    .logged-in.admin-bar & {
      top: 46px;
      @media screen and (min-width: 783px) {
        top: 32px;
      }
    }
  }
  .menu-btn {
    z-index: 101;
    position: absolute;
    right: $grid-gutter-width/2;
    top: 0;
    background-color: $slate-light;
    width: $grid-gutter-width*2;
    height: $grid-gutter-width*2;
    padding-left: 16px;
    padding-right: 16px;
    border: none;
    border-radius: $grid-gutter-width;
    cursor: pointer;
    &:hover {
      background-color: $slate-dark;
    }
    &:focus {
      outline: none;
    }
    .icon-bar {
      display: block;
      width: 28px;
      height: 5px;
      border-radius: 1px;
      background-color: $white;
      transition: all 0.3s;
//       margin-left: 9px;
      &+.icon-bar {
        margin-top: 5px;
      }
    }
    .pushy-open-right & {
      background-color: $white;
      .icon-bar {
        background-color: $brand-primary;
        border-radius: 2px;
        height: 7px;
        width: 25px;
        margin-left: 9px;
//         margin-left: 17px;
        &.top-bar {
          transform: rotate(45deg);
          transform-origin: -22% 10%;
        }
        &.mid-bar {
          opacity: 0;
        }
        &.btn-bar {
          transform: rotate(-45deg);
          transform-origin: -22% 90%;
        }
      }
      &:hover {
        .icon-bar {
          background-color: $brand-secondary;
        }
      }

    }
  }
  .alertbar {
    min-height: $alertbar-min-height;
    display: none;
    background-color: $brand-primary;
    color: $black;
    .alertbar-engaged & {
      display: block;
    }
    .alertbar-message {
      text-align: center;
      font-size: $font-size-sm;
      margin-top: $alertbar-min-height/5;
      margin-bottom: $alertbar-min-height/5;
      p {
        margin: 0 $alertbar-dismiss-width;
      }
      a {
        color: $white;
      }
      .closealert {
        color: $black;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        text-decoration: none;
        @include hover-focus {
          color: $white;
        }
      }
    }
    @include media-breakpoint-up(md) {
      padding-left: $grid-gutter-width;
      padding-right: $grid-gutter-width;
    }

  }
}
.pushy {
  width:375px;
  &.pushy-right {
    transform: translate3d(375px, 0, 0);
    .pushy-open-right & {
      transform: translateZ(0);
    }
  }

}
