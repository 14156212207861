/* GRID
  --------------------------------------- */
@mixin wp-full-width {
  width: 100vw !important;
  max-width: 100vw !important;
  margin-left: calc(50% - 50vw);
}
@mixin wp-full-width-no-top {
  @include wp-full-width;
  margin-top: 0;
  margin-right: 0;
}
@mixin wp-full-width-no-padding {
  @include wp-full-width;
/*
  margin-top: 0;
  margin-bottom: 0;
*/
  margin-right: 0;
}
@mixin main-column-width {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
@mixin main-column-width-wide {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}

@mixin main-column-width-full {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12);
    @include make-col-offset(0);
  }
}
@mixin last-child-zero-margin {
  &>:last-child {
    margin-bottom: 0;
  }
}


/* LINKS & ACTIONS
  --------------------------------------- */
@mixin link-underline {
  text-decoration: underline;
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-underline-hover {
  text-decoration: none;
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-no-underline {
  text-decoration: none;
  @include hover-focus {
    text-decoration: none;
  }
}
@mixin link-caret-transition {
  i[class*="fa-"] {
    @include transition($caret-transition);
  }
  &:not(.collapsed) {
    i[class*="fa-"] {
      transform: rotate(180deg);
    }
  }
}
@mixin link-plus-transition {
  @include link-caret-transition;
  &:not(.collapsed) {
    i[class*="fa-"] {
      transform: rotate(45deg);
    }
  }
}

@mixin tile-hover {
  figure {
    overflow: hidden;
    img {
      opacity: .75;
      will-change: transform, opacity;
      transition: all ease .4s;
    }
  }
  &:hover,&:active,&:focus,&:focus-visible {
    figure {
      img {
        transform: scale(1.05);
        opacity: 1;
      }
    }
  }
}

@mixin tile-grid {
  text-align: left;
  .hentry {
    margin-bottom: $grid-gutter-width;
    &.tile-item, a.article-link {
      @include last-child-zero-margin;
      @include link-no-underline;
      color: $body-color;
      height: 100%;
      flex-direction: column;
      &>.entry-meta {
        font-size: $font-size-xs;
        text-transform: uppercase;
        letter-spacing: .1em;
        margin-bottom: .25em;
        flex-direction: row;
        justify-content: space-between;
        time {
          letter-spacing: .05em;
        }
      }

      &>figure {
        @include figure-aspect-ratio('1/1');
        background-color: #333;
      }
      &>.entry-details {
        .entry-title {
          @include transition($link-transition);
          @include condensify;
          text-transform: none;
          font-size: 1.5rem;
          line-height: 1.075;
        }
        .entry-summary {
          @include last-child-zero-margin;
        }
        .event-date {
          margin-bottom: 5px;
          font-weight: bold;
        }
      }
      &.caption {
        @include caption-overlay;
      }
    }
    a.article-link {
      @include tile-hover;
      &:hover,&:active,&:focus,&:focus-visible {
        &>.entry-details {
          .entry-title {
            color: $brand-primary;
          }
        }
      }
      &.caption {
        @include caption-hover;
      }

    }
  }
}
@mixin caption-overlay {
  figure {
    position: relative;
    img {
      opacity: .3;
    }
    figcaption {
      @include condensify;
      @include transition($link-transition);
      line-height: 1.1;
      text-transform: none;
      position: absolute;
      width: 100%;
      top: 50%;
      transform: translateY(-50%);
      color: $brand-primary;
      text-align: center;
      font-size: $font-size-lg;
      span {
        display: block;
        &.caption-title {
          font-size: $font-size-hero-sm;
          line-height: .9;
        }
      }
      &.title-caps {
        .caption-title {
          text-transform: uppercase;
        }
      }
      &.detail-caps {
        .caption-detail {
          text-transform: uppercase;
        }
      }
      &.all-caps {
        text-transform: uppercase;
      }
    }
  }
}
@mixin caption-hover {
  &:hover,&:active,&:focus,&:focus-visible {
    img {
      opacity: .8;
    }
    figcaption {
      opacity: .4;
    }
  }

}


/* TYPE
  --------------------------------------- */
@mixin condensify {
  font-family: $font-family-condensed;
  font-weight: $font-weight-base;
  text-transform: uppercase;
  line-height: .95;
}
@mixin h1ify {
  @include condensify;
  text-transform: none;
  margin: 0 0 $grid-gutter-width;
  font-size: $font-size-h1;
  @include media-breakpoint-up(md) {
   margin-bottom: $grid-gutter-width*1.33;
  }
}
@mixin h1ify-lg {
  @include h1ify;
  text-transform: uppercase;
  font-size: $font-size-hero-sm;
}
@mixin headline-size {
  font-size: $font-size-hero-sm;
  @include media-breakpoint-up(md) {
    font-size: $font-size-hero-md;
  }
  @include media-breakpoint-up(lg) {
    font-size: $font-size-hero;
  }
}
@mixin allcaps-style {
  font-family: $font-family-sans;
  text-transform: uppercase;
  letter-spacing: .1em;
}

/* IMAGES
  --------------------------------------- */
@mixin figure-aspect-ratio($ratio) {
  aspect-ratio: #{$ratio};
  overflow: hidden;
  isolation: isolate;
  img {
    object-fit: cover;
    display: block;
    width: 100%;
    height: 100%;
  }
}


/* BLOCK ELEMENTS
  --------------------------------------- */
@mixin d-flex {
  display: flex !important;
}
@mixin list-normalize-keep-margin {
  padding: 0;
  list-style: none;
}
@mixin list-normalize {
  @include list-normalize-keep-margin
  margin: 0;
}
@mixin list-inline-block {
  li {
    display: inline-block;
  }
}
@mixin list-normalize-inline-block {
  @include list-normalize;
  &>li {
    display: inline-block;
  }
}
@mixin list-normalize-flexbox {
  @include list-normalize;
  @include d-flex;
  &>li {
    @include d-flex;
  }
}


@mixin fact-bubbles {
  margin-top: $grid-gutter-width;
  margin-bottom: $grid-gutter-width;
  justify-content: center;
  .fact {
    margin-bottom: $grid-gutter-width;
    justify-content: center;
    article {
      flex-direction: column;
      overflow: hidden;
      display: flex;
      justify-content: center;
      padding: $grid-gutter-width/2;
      margin: 0 auto;
      width: 100%;
      border-radius: 50%;
      aspect-ratio: 1/1;
/*
      height: 240px;
      border-radius: 120px;
*/
      p {
        @include h1ify;
        text-transform: uppercase;
      }
      small {
        font: normal $font-size-lg $font-family-sans;
        letter-spacing: normal;
        display: block;
        margin-top: .75em;
      }
      @include media-breakpoint-down(md) {
        font-size: $font-size-h2;
      }
      @include media-breakpoint-up(md) {
/*
        padding: $grid-gutter-width/3;
        width: 210px;
*/
/*
        height: 210px;
        border-radius: 105px;
*/
      }
      @include media-breakpoint-up(lg) {
/*
        padding: $grid-gutter-width;
        width: 288px;
*/
/*
        height: 288px;
        border-radius: 144px;
*/
      }
      @include media-breakpoint-up(xl) {
//         width: 350px;
/*
        height: 350px;
        border-radius: 175px;
*/
      }
      p {
        margin: 0 auto;
      }
    }
  }
}
