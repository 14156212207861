// WordPress Generated Classes
// http://codex.wordpress.org/CSS#WordPress_Generated_Classes

// Media alignment
.alignnone {
  margin-left: 0;
  margin-right: 0;
  max-width: 100%;
  height: auto;
}
.aligncenter {
  display: block;
  margin: ($spacer / 2) auto;
  height: auto;
}
.alignleft,
.alignright {
  margin-bottom: ($spacer / 2);
  height: auto;
}
@include media-breakpoint-up(sm) {
  // Only float if not on an extra small device
  .alignleft {
    float: left;
    margin-right: ($spacer / 2);
  }
  .alignright {
    float: right;
    margin-left: ($spacer / 2);
  }
}

// Captions
.wp-caption {
  @extend .figure;
}
.wp-caption > img {
  @extend .figure-img;
  @extend .img-fluid;
}
.wp-caption-text {
  @extend .figure-caption;
}
.wp-caption-text {
  @extend .figure-caption;
}


.wp-block-buttons {
  margin-bottom: $grid-gutter-width;
  justify-content: center;
}

// Text meant only for screen readers
.screen-reader-text {
  @extend .sr-only;
  @extend .sr-only-focusable;
}


main.main {
  &>.vcet-block-group {
    @include wp-full-width;
    &>.wp-block-group__inner-container {
      @extend .container-fluid;
      &>.wp-block-group {
        @include make-row();
        &>.wp-block-group__inner-container {
          @include main-column-width;
          @include last-child-zero-margin;
          h2 {
            @include h1ify-lg;
          }
          &>ul {
            text-align: left;
          }
          :first-child {
            &.vcet-block {
              margin-top: -$block-gutter;
            }
          }
        }
      }
    }
  }
}
.wp-block-columns.is-layout-flex {
  align-items: inherit;
  .wp-block-column {
    text-align: left;
    width: 100%;
  }
}
