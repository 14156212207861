.btn,.wp-block-button .wp-block-button__link {
  font-family: $font-family-sans;
  text-transform: uppercase;
  height: $btn-height;
  letter-spacing: .1em;
  line-height: $btn-line-height;
  color: $btn-color;
  background-color: $btn-bg;
  border: $btn-border-width solid $btn-border-color;
  border-radius: $border-radius;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  text-align: center;
  white-space: normal;
  font-weight: $btn-font-weight;
  vertical-align: middle;
  padding: $btn-padding-y $btn-padding-x;
  font-size: $font-size-base;
  &:focus {
    color: $btn-color;
    background-color: $btn-bg;
    text-decoration: none;
  }
  &:hover {
    color:$btn-hover-color;
    background-color:  $btn-hover-bg;
    text-decoration: none;
  }
  &.btn-icon-right {
    [class*=fa-] {
      margin-left: var(--gap-sm);
    }
  }
  &.btn-icon-left {
    [class*=fa-] {
      margin-right: var(--gap-sm);
    }
  }

}
.btn-primary,
.wp-block-button.is-style-primary .wp-block-button__link,
.wp-block-button.is-style-primary-xl .wp-block-button__link {
  color: $btn-primary-color;
  background-color: $btn-primary-bg;
  border-color: $btn-primary-bg;
  &:focus {
    color: $btn-primary-color;
    background-color: $btn-primary-bg;
    border-color: $btn-primary-bg;
  }
  &:hover {
    color: $slate-dark;
    background-color: $btn-primary-bg;
    border-color: $btn-primary-bg;
  }
}
.btn-alt,
.wp-block-button.is-style-default-alt .wp-block-button__link,
.wp-block-button.is-style-default-alt-xl .wp-block-button__link {
  color: $btn-alt-color;
  background-color: $btn-alt-bg;
  border-color: $btn-alt-border-color;
  &:focus {
    color: $btn-alt-color;
    background-color: $btn-alt-bg;
  }
  &:hover {
    color: $btn-alt-hover-color;
    background-color: $btn-alt-hover-bg;
  }
}
.btn-orange {
  &:hover {
    color: $orange;
  }
}
.btn-slate {
  &:hover {
    color: $slate;
  }
}
.btn-slate-dark {
  &:hover {
    color: $slate-dark;
  }
}
.btn-xl,
.wp-block-button.is-style-primary-xl .wp-block-button__link,
.wp-block-button.is-style-default-xl .wp-block-button__link,
.wp-block-button.is-style-default-alt-xl .wp-block-button__link {
  @include condensify;
  font-size: $font-size-xl;
  letter-spacing: normal;
  padding: 50px 30px;
  border-radius: 60px;
}
